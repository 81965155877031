<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> マスタ管理 - アポ取得リスト</h1>
    </header>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <div v-else class="col-sm-36 col-md-28 col-lg-24">
            <table class="table">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-1"></th>
                        <th class="text-center col-8">アポ取得リスト</th>
                        <th class="text-center col-3"></th>
                    </tr>
                </thead>
                <template v-if="appointment_sources.length">
                    <draggable
                            v-model="appointment_sources"
                            item-key="appointment_source_id"
                            tag="tbody"
                            handle=".handle"
                            @end="sort()"
                    >
                        <template #item="{element}">
                            <tr>
                                <td class="text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                                <td class="align-middle">
                                    <form-input v-model="element.appointment_source_name"/>
                                </td>
                                <td class="text-center">
                                    <button type="button" class="btn btn-info me-2" @click="update(element)">保存</button>
                                    <button type="button" class="btn btn-outline-danger ms-2" @click="removeReady(element)">削除</button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="align-middle"><form-input v-model="appointment_source_to_create.appointment_source_name" /></td>
                        <td class="text-center">
                            <button class="btn btn-info" @click="create">追加</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import AppointmentSource from '@/models/entities/appointment-source';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';

export default {
    name: 'PageAppointmentSource',
    components: {
        draggable,
        InlineLoader,
        FormInput,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading: false,
            // 表示データ
            appointment_sources: [],
            // 新規
            appointment_source_to_create: new AppointmentSource(),
            // 削除
            appointment_source_to_remove: null,
        }
    },
    mounted() {
        this.fetchAppointmentSources();
    },
    methods: {
        // 一覧取得
        fetchAppointmentSources() {
            //表示データ初期化
            this.appointment_sources.splice(0);
            //ローディング開始
            this.loading = true;
            this.$http.get('/master/appointment-source')
            .then(response => {
                for (let row of response.data) {
                    this.appointment_sources.push(new AppointmentSource(row));
                }
            })
            .finally(() => {
                //ローディング終了
                this.loading = false;
            });
        },
        // 新規登録
        create() {
            // 空チェック
            if (this.$helper.isBlank(this.appointment_source_to_create)) {
                this.showErrorMessage('アポ取得リスト名を入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.post('/master/appointment-source', this.appointment_source_to_create)
            .then(response => {
                // リストに追加
                this.appointment_sources.push(new AppointmentSource(response.data));
                // 入力欄初期化
                this.appointment_source_to_create = new AppointmentSource();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 保存（編集）
        update(appointment_source) {
            // 空チェック
            if (this.$helper.isBlank(appointment_source.appointment_source_name)) {
                this.showErrorMessage('アポ取得リスト名を入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/master/appointment-source/${appointment_source.appointment_source_id}`, appointment_source)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 削除モーダル
        removeReady(appointment_source) {
            this.appointment_source_to_remove = appointment_source;
            this.$refs.confirmRemove.show();
        },
        // 削除処理
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/master/appointment-source/${this.appointment_source_to_remove.appointment_source_id}`)
            .then(() => {
                // リストから削除
                const remove_index = this.appointment_sources.findIndex((row) => row.appointment_source_id === this.appointment_source_to_remove.appointment_source_id);
                this.appointment_sources.splice(remove_index, 1);

                this.appointment_source_to_remove = null;
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 並び順更新
        sort() {
            this.startScreenLoading();

            const appointment_sources = this.appointment_sources.map(appointment_source_object => appointment_source_object.appointment_source_id);

            this.$http.post('/master/appointment-source/sort', {
                appointment_sources: appointment_sources,
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>

</style>
