/**
 * アポ取得リスト
 */
import { isBlank } from '@/utilities/typing'

class AppointmentSource {
    appointment_source_id;
    appointment_source_name;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.appointment_source_id = properties.appointment_source_id;
        this.appointment_source_name = properties.appointment_source_name;
    }
}

export default AppointmentSource;
